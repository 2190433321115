const enTranslation = {
  enter_password: 'Enter password',
  phone_number: 'Phone number',
  already_a_member: 'Already a member?',
  login: 'Login',
  username: 'Username',
  placeholder_username: 'Please enter your phone number',
  placeholder_password: 'Please enter your password',
  placeholder_confirm_password: 'Please confirm your password',
  placeholder_name: 'Please confirm your name',
  placeholder_email: 'Please confirm your email',
  placeholder_phone: 'Please confirm your phone',
  password: 'Password',
  forgot_password: 'Forgot Password?',
  sign_up: 'Sign up',
  text_login_policy: 'By continuing, you agree to GladiusArt’s',
  and: 'and',
  general_trading_conditions: 'General Trading Conditions',
  privacy_policy: 'Privacy Policy',
  login_to_your_account: 'Login to your account',
  dont_have_an_account: "Don't have an account?",
  update_successfully: 'Updated successfully',
  remove_successfully: 'Removed successfully',
  name: 'Name',
  email: 'Email',
  role: 'Role',
  my_account: 'My Account',
  save_changes: 'Save changes',
  cancel: 'Cancel',
  view_profile: 'View profile',
  change_password: 'Change password',
  notification_preferences: 'Notification Preferences',
  social_connects: 'Social Connects',
  delete_account: 'Delete Account',
  home: 'Home',
  gallery: 'Gallery',
  my_gallery: 'My gallery',
  all_work: 'All work',
  artist_profile: 'Artist Profile',
  certificate: 'Certificate',
  upload_your_first_artwork: 'Upload your first artwork',
  upload_artwork: 'Upload ArtWork',
  edit_profile: 'Edit Profile',
  sign_up_new_account: 'Sign up a new account',
  already_member: 'Already a member?',
  confirm_password: 'Confirm password',
  invalid_email: 'Please enter a valid email',
  invalid_phone: 'Please enter a valid phone number',
  invalid_password:
    'Password must be strong! ( at least 1 uppercase, 1 lowercase, 1 number and more than 8 characters )',
  validate_password_not_match: 'Password does not match',
  registration_successful: 'Registration Successful',
  sign_out: 'Sign out',
  account_setting: 'Account settings',
  setting: 'Setting',
  help: 'Help',
  login_in: 'Log in',
  follow: 'Follow',
  message: 'Message',
  artist_statement: 'My statement',
  collections: 'Collections',
  artworks: 'Artworks',
  followers: 'Followers',
  text_guide_add_statement: 'You can tap the edit icon to start to add your statement here.',
  text_guide_add_statement_collector:
    "This is where collectors can introduce and share the content of the collection, their collecting viewpoint, vision and message. Click the '+' button to update the collector's statement here.",
  text_guide_add_statement_artist:
    "This is where artists can share their views, thoughts on artistic practice, inspiration, vision and message. Click the '+' button to update the artist's statement here.",
  place_holder_statement: 'Write your statement here',
  see_more: 'See more',
  add_a_banner: 'Add a banner',
  text_banner_size: 'Optimal dimension 1440 x 160',
  art_vault_version: 'GladiusArt Version',
  remove: 'Remove',
  replace_banner_image: 'Replace banner image',
  maximum_200_characters: 'Maximum 200 characters allowed',
  update_failed: 'Update failed',
  title: 'Title',
  category: 'Category',
  your_images_will_be_up_here: 'Your images will be up here',
  click_to_upload_image: 'Click + to start upload your artwork cover and other supporting images',
  text_drag_and_drop_image: 'You can drag and drop an image, or',
  browse: 'Browse',
  text_choose_category: 'How would you categorize this artwork?',
  next: 'Next',
  create_successfully: 'Created successfully',
  submit: 'Submit',
  select_a_status: 'Select a status',
  status: 'Status',
  artwork_title: 'Artwork Title',
  give_your_artwork_title: 'Give your artwork a title',
  write_your_description_about_the_artwork: 'Write your description about the artwork...',
  write_your_note_about_the_artwork: 'Write your note about the artwork...',
  description: 'Description',
  creation_description: 'Creation description',
  note: 'Note',
  subject: 'Subject',
  select_subject: 'Select a subject',
  meidum: 'Meidum',
  color: 'Color',
  select_main_colors_your_artwork: 'Select main colors of your artwork',
  select_style_your_artwork: 'Select style of your artwork',
  select_medium_on_material: 'Select a medium on material',
  style: 'Style',
  orientation: 'Orientation',
  size: 'Size',
  length: 'Length',
  enter_size_of_artwork: 'Enter {{size}} of artwork',
  width: 'Width',
  depth: 'Depth',
  wight: 'Wight',
  image_could_not_be_loaded: 'Image could not be loaded',
  artwork_upload: 'Artwork Upload',
  bulk_upload: 'Bulk Upload',
  new_collection: 'New Collection',
  upload_file_failed: 'Upload file failed',
  create_artwork_failed: 'Create artwork failed',
  show_less: 'Show Less',
  visibility: 'Visibility',
  text_art_work_not_visible: 'The artwork is not visible on your Artist Profile',
  text_art_work_display_or_hide:
    'You can choose to display or hide this artwork on your Artist Profile.',
  text_art_work_auto_hide_and_update:
    'The artwork automatically hides and updates to “Not for sale” in the valid time.',
  text_art_work_automatic_back_status:
    'When the rental period expires it automatically back to the previous status.',
  display_artwork: 'Display artwork',
  rental_period: 'Rental Period',
  from: 'From',
  to: 'To',
  this_artwork_only_shows_for_me: 'This artwork only shows for me.',
  hide_price_only_show_to_me: 'Hide price, only show to me',
  the_artwork_is_public_for_everyone: 'The artwork is public for everyone.',
  price: 'Price',
  enter_price: 'Enter price',
  currency: 'Currency',
  display_price: 'Display price',
  exhibition_period: 'Exhibition Period',
  the_artworks_is_public_for_everyone:
    'The artworks is public for everyone and automatically updates  to “Available for sale” when the Exhibition period expires.',
  private: 'Private',
  everyone: 'Everyone',
  public_price_for_eveyone: 'Public price for eveyone',
  artist: 'Artist',
  medium: 'Medium',
  gallaries: 'Gallaries',
  artwork_detail: 'Artwork detail',
  about_the_artwork: 'About the artwork',
  day_of_creation: 'Dated',
  materials: 'Materials',
  inquire: 'Inquire',
  medium_on_material: 'Medium on material',
  creation_year: 'Creation year',
  show_creation_year_as_period: 'Show creation year as period',
  before_20th_century: 'e.g: Before 20th century',
  edition_number: 'Edition number',
  platform_can_not_sort_by_period: 'Note: Platform can’t sort by the period',
  image_list_is_empty: 'Image list is empty. Please add images.',
  status_is_required: 'Status is required. Please enter status.',
  title_is_required: 'Title is required. Please enter title.',
  length_is_required: 'Height is required. Please enter height.',
  width_is_required: 'Width is required. Please enter width.',
  creation_year_is_required: 'Creation year is required',
  medium_is_required: 'Medium is required',
  text_standard_resolution: 'The standard resolution of Full HD images is 1920x1080px.',
  text_upload_max_5_image:
    'You can upload maximum 5 images of your artwork. Your first image is your artwork cover.',
  front: 'Front',
  back: 'Back',
  detail: 'Detail',
  signature: 'Signature',
  damage: 'Damage',
  create_new_artwork_location: 'Create new artwork location',
  location_name: 'Location name',
  please_input_location_name: 'Please input a location name',
  select_location: 'Select Location',
  artwork_location: 'Artwork location',
  selecting_creation_year_not_be_greater_than_current_year:
    'Selecting the creation year can not be greater than the current year.',
  the_location_name_already_exists: 'The location name already exists,please choose another name.',
  create_award_failed: 'Create award failed',
  year: 'Year',
  edit_artist_profile: 'Edit Artist Profile',
  basic_information: 'Basic Information',
  artist_nickname: 'Artist Nickname',
  enter_your_fullname: 'Enter your full name',
  live_at: 'Live at',
  write_anything: 'Write anything...',
  enter_your_email: 'Enter your email',
  enter_your_phone_number: 'Enter your phone number',
  address: 'Address',
  enter_your_address: 'Enter your address',
  website: 'Website',
  enter_an_url: 'Enter an url',
  add_website: 'Add website',
  social_links: 'Social links',
  url: 'Url',
  add_social_link: 'Add social link',
  text_description_artist_statement:
    'You can use artist statements to describe about your artistic style in different time period',
  text_placeholder_about_artist: 'Brief description about yourself',
  about_artist: 'About Artist',
  artist_statements: 'Artist Statements',
  enter_a_year: 'Enter a year',
  write_something_to_describe: 'Write something to describe...',
  year_of_birth_and_hometown: 'Year and place of birth',
  artist_biography: 'Biography',
  add_training_background: 'Add training background',
  training_background: 'Training Background',
  text_add_detail_training_background:
    'If you are a self-taught artist, please also fill in the year you started and provid details about it in the description.',
  membership: 'Membership',
  add_membership: 'Add membership',
  enter_year: 'Enter year',
  upload_media: 'Upload media',
  public_on_profile: 'Public on Profile',
  add: 'Add',
  you_can_upload_file_with_format:
    'You can upload maximum 5 files with format .jpg, .png, .jpeg, .pdf. File size is under 200MB.',
  awards: 'Awards',
  added_events_will_automatically:
    'Added events will be automatically arranged in chronological order when displayed on your profile page',
  invalid_website: 'Invalid website',
  invalid_url: 'Invalid URL',
  text_recommend_use_portrait: 'We recommend you to use your portrait.',
  upload: 'Upload',
  text_confirm_delete: 'Are you sure want to delete ?',
  delete_success: 'Deleted Successfully',
  delete_failed: 'Delete Failed',
  edit_success: 'Edit successful',
  edit_failed: 'Edit Failed',
  please_enter_correct_link: 'Please enter the correct link',
  please_enter_correct_year: 'Please enter the correct year',
  please_select_social_platform: 'Please select social platform',
  please_typing_url: 'Please typing url',
  please_typing_year: 'Please typing year',
  solo_exhibitions: 'Solo Exhibitions',
  group_exhibitions: 'Group Exhibitions',
  publications: 'Publications',
  lives_and_works_in: 'Lives and works in',
  contact: 'Contact',
  about: 'About',
  biography: 'Biography',
  are_you_sure_you_want_delete_your: 'Are you sure you want to delete your {{name}}',
  edit_solo_exhibition: 'Edit Solo Exhibition',
  edit_group_exhibition: 'Edit Group Exhibition',
  edit_publication: 'Edit Publication',
  edit_award: 'Edit Award',
  special_works: 'Special Works',
  delete_publication: 'Delete Publication',
  delete_exhibition: 'Delete Exhibition',
  delete_award: 'Delete Award',
  artists: 'Artists',
  search_artist_name: 'Search by artist name...',
  error_download_pdf_file: 'Error downloading PDF file',
  view_artist_profile: 'View artist profile',
  text_max_length_error: 'Exceeded maximum character length of {{length}}',
  text_placeholder_place_of_birth: 'Enter your place of birth (e.g: Born in Ha Noi)',
  text_placeholder_membership: 'Enter your place of membership (e.g: Member of Bloomsbury Group)',
  text_placeholder_training_background:
    'Enter your training background (e.g: Self-taught/Graduated from...)',
  year_of_birth: 'Year of birth',
  place_of_birth: 'Place of birth',
  shipping_info: 'Shipping info',
  text_cetificate_delivery:
    'A physical certificate will be delivered to you in <strong>10 days</strong>. Please check your shipping information before your submit this form.',
  request_certificate: 'Request Certificate',
  exhibition: 'Exhibition',
  certificate_authenticity: 'Certificate of Authenticity',
  this_certifies_the_artwork_presented_below:
    'I undersigned artist, <strong>{{name}}</strong> certify that the artwork presented below is an original artwork. This certificate was issued on <strong>{{timeDay}}</strong> by {{name}} and guarantees the accuracy of the details and descriptions.',
  this_certificate_belong_to: 'This certificate belongs to <strong>{{name}}</strong>',
  text_certificate_owner_info: 'Non-account, born in {{year}}, Address {{address}}',
  text_certificate_contract_number:
    ', according to transaction contract number {{contractNumber}}.',
  only_the_current_owner:
    'Note: Only the current owner is authorized to transfer this certificate to new ownership, please contact GladiusArt for assistance.',
  the_information_in_the_certificate:
    'Important note: The information in the certificate, once successfully issued, cannot be changed anymore. Please check the information carefully before proceeding to the next step.',
  original_certificate: 'Original Certificate',
  edition: 'Edition',
  artist_signature: 'Artist’s signature',
  property_rights: 'Property rights',
  only_the_current_owner_authorized:
    'Note: Only the current owner is authorized to transfer this certificate to new ownership, please contact GladiusArt for assistance.',
  artwork_information: 'Artwork Information',
  owner_information: 'Owner Information',
  artist_name: 'Artist Name',
  owner_name: 'Owner Name',
  contract_number: 'Contract Number',
  please_enter: 'Please enter...',
  account_id: 'Account ID',
  unique: 'Unique',
  identity_card_number: 'Identity Card Number',
  total_edition_number_is_required: 'Total edition number is required',
  total_edition_number_not_greater_than: 'Total edition number not be greater than 20',
  request_create_certificate_successfully: 'Request create certificate successfully',
  certificate_of_authenticity: 'Certificate of Authenticity',
  issued_on_gladius_art: 'Issued on GladiusArt',
  period_year: 'Period year',
  period_created_must_be_at_most_50_characters: 'Period created must be at most 50 characters',
  measurement: 'Measurement',
  does_artwork_have_more_than_one_section: 'Does artwork have more than one section?',
  yes: 'Yes',
  no: 'No',
  enter: 'Enter',
  please_input_total_sections: 'Please input total sections (must >= 2) and must not exceed 10',
  total_sections: 'Total sections',
  please_select_your_artwork_has_more_than_one_section:
    'Please select your artwork that has more than one section ?',
  add_signature: 'Add signature',
  welcome_to: 'Welcome to',
  please_login_your_account_to_view_more: 'Please login your account to view more detail',
  select_image: 'Select image',
  upload_your_handwritten_signature_image: 'Upload your handwritten signature image',
  select_your_handwritten: 'Click the button to upload',
  by_signing_this_certificate:
    'By signing this certificate with an electronic signature, I agree that signature will be as valid as handwritten signatures to the extent allowed by local law.',
  preview: 'Preview',
  example: 'Example',
  we_recommend_that_you_sign_on:
    'We recommend writing your name on a large white paper with a black pen, ensuring that the signature covers the entire page. Use your smartphone, tablet, or home scanner to capture a well-lit, non-shiny, and clear image of your signature.',
  select_edition_number: 'Select edition number',
  change_signature: 'Change signature',
  height_is_required: 'Height is required',
  manage_editions: 'Manage Editions',
  search_by_artwork_name: 'Search by artwork name',
  search_by_collection_name: 'Search by collection name',
  artwork_name: 'Artwork name',
  id_certificate: 'ID Certificate',
  owner: 'Owner',
  location: 'Location',
  contract_validate: 'Contract Validate',
  your_signature: 'Your signature',
  show_more: 'Show more',
  edition_volume: 'Edition Volume',
  more_editions: '{{number}} more editions',
  manage_certificates: 'Manage Certificates',
  issued_date: 'Issued Date',
  edition_of_certificate: 'Edition of Certificate',
  updated_date: 'Updated Date',
  processing_status: 'Processing Status',
  action: 'Action',
  view_certificate: 'View certificate',
  check_log: 'Check log',
  request_admin_help: 'Request admin help',
  please_select: 'Please select',
  which_best_describes_you: 'Which best describes you?',
  collector: 'Collector',
  activity_log: 'Activity Log',
  enter_an_artist_id_or_name: 'Enter an artist ID or name',
  please_select_the_artist: 'Please select the artist',
  request_to: 'Request to',
  recipient: 'Recipient',
  text_act_button_next: 'Next: Preview Certificate',
  save_as_draft: 'Save as draft',
  choose_account_type: 'Choose account type',
  if_you_need_more_info: 'If you need more info, please check out Help page.',
  continue: 'Continue',
  please_choose_account_type: 'Please choose account type',
  non_account_artist: 'Non account Artist',
  save_and_send_request: 'Save and send request',
  attachments: 'Attachments',
  upload_files: 'Upload files',
  you_can_upload_maximum_5_files:
    'You can upload maximum 5 files including .jpg, .png, .jpeg, .pdf .',
  please_select_request_to: 'Please select request to',
  please_upload_image: 'Please upload image',
  issued_certificates: 'Issued Certificates',
  requested_certificate: 'Requested Certificate',
  requesting_certificate: 'Requesting Certificate',
  artist_gallery_name: 'Artist/Gallery Name',
  review_certificate_request: 'Review Certificate Request',
  requested_date: 'Requested Date',
  approve_request: 'Approve request',
  reject_request: 'Reject request',
  cancel_request: 'Cancel Request',
  yes_canccel: 'Yes, cancel',
  are_you_sure_exit_certificate: 'Are you sure you want to exit this request?',
  send_certificate_request: 'Send Certificate Request',
  are_you_sure_send_certificate_request:
    'Are you sure you want to send your certificate request to {{artistName}}?',
  request_certificate_success: 'Request certificate success',
  approve_success: 'Approve success',
  please_select_signature: 'Please select signature',
  reject_reason: 'Reject reason',
  text_placeholder_reject_certificate_request:
    'Please provide a reason why you want to reject this certificate request...',
  reject_success: 'Reject success',
  text_confirm_approve_certificate_request:
    'Are you sure you want to approve the certificate request from <strong>{{colectorName}}<strong>? ',
  owned_certificates: 'Owned certificates',
  edit_artwork: 'Edit Artwork',
  delete_artwork: 'Delete Artwork',
  please_select_main_image: 'Please select main image',
  text_confirm_delete_artwork_no_certificate:
    'All your photos and information will be permanently removed and you won’t be able to see them again.',
  text_confirm_delete_artwork_has_certificate:
    'This artwork had created certificate, you are only allowed to hide its photo and detail page as an private artwork and you will be only see them in your Manage Storage.',
  text_delete_artwork_success: 'You have successfully deleted the artwork.',
  text_move_artwork_to_store: 'Your artwork is successfully moved to your Manage Storage.',
  create_certificate: 'Create certificate',
  artwork_cover: 'Artwork Cover',
  back_to_manage_certificate: 'Back to Manage Certificate',
  edit_certificate_request: 'Edit Certificate Request',
  request_rejected: 'Request rejected',
  back_side: 'Back',
  select_year: 'Select year',
  update: 'Update',
  available: 'Available for sale',
  sold: 'Sold',
  not_for_sale: 'Not for sale',
  lost: 'Lost',
  donated_gifted: 'Donated/Gifted',
  under_maintenance: 'Under Maintenance',
  consignment: 'Consignment',
  download_pdf: 'Download PDF',
  share: 'Share',
  request_received: 'Request received',
  request_approved: 'Request approved',
  request_denied: 'Request denied',
  vietnamese: 'Vietnamese',
  english: 'English',
  validate_form: 'By validating this form:',
  acknowledge_responsibilities:
    'I acknowledge all my responsibilities for the declared authenticity of the work.',
  informed_artcertificate_role:
    'I acknowledge having been informed that Artcertificate does not in any way engage its responsibility for the authenticity of the works which are deposited on its website, but only has a role of guide in the drafting and printing of certificates of authenticity.',
  warned_false_declaration:
    'I acknowledge having been warned of the consequences in the event of false declaration or attempted falsification of certificates of authenticity; whoever violates the articles of the Marcus Decree will be liable to the fines provided for fifth class contraventions; this may result in the cancellation of the sale and the payment of damages.',
  authorize_display_photo:
    'I authorize Artcertificate to display the photo of the work on the website in a reduced format and with a water mark so that buyers can check that their work corresponds to the photo registered when the certificate was created. Liability of Artcertificate: Artcertificate does not engage in any way its responsibility concerning the authenticity of the works which are recorded on its website; this responsibility is entirely assumed by the natural or legal person who uses the services of Artcertificate for the drafting of its certificates of authenticity.',
  terms_conditions_agreement: 'I have read and agree to Gladius Art’s Terms and Conditions.',
  validate: 'Validate',
  replace: 'Replace',
  approve: 'Approve',
  reject: 'Reject',
  painting: 'Painting',
  sculpture: 'Sculpture',
  other: 'Other',
  save: 'Save',
  link_has_been_copied: 'Link has been copied',
  this_field_is_require: 'This field is require',
  site_language: 'Site Language',
  en_english: 'EN - English',
  vi_vietnamese: 'VN - Vietnamese',
  language_setting: 'Language setting',
  search_artwork_or_artist_name: 'Search by artwork or artist’s name',
  editions: 'Editions',
  certificates: 'Certificates',
  manage_storage: 'Manage Storage',
  manage_artworks: 'Manage Artworks',
  filtered: 'Filtered',
  clear_all: 'Clear All',
  enter_min_price: 'Enter min price',
  enter_max_price: 'Enter max price',
  min: 'Min',
  max: 'Max',
  filters: 'Filters',
  view_detail: 'View detail',
  apply: 'Apply',
  create: 'Create',
  account: 'Account',
  exhibition_link: 'Exhibition link',
  matching_result_found: 'Matching result found',
  we_have_found_a_user: 'We have found a user that match your information on the platform.',
  please_enter_correct_email: 'Please enter correct email',
  search_artist: 'Search Artist',
  no_matching_result: 'No matching result',
  send_request: 'Send request',
  no_account_exist_on_platform:
    'No account exist on the platform. Do you want us to send a request to create account to the email <strong>{{email}}</strong>',
  if_not_click_cancel: 'If no, please click "Cancel" and try to search again.',
  price_low_to_high: 'Price low to high',
  price_high_to_low: 'Price high to low',
  date_old_to_new: 'Date old to new',
  date_new_to_old: 'Date new to old',
  enter_email_to_search: 'Enter email to search',
  medium_max_length: 'Medium must be no more than 100 characters long',
  style_max_length: 'Style must be no more than 100 characters long',
  subject_max_length: 'Subject must be no more than 100 characters long',
  please_search_again_for_the_artist: 'Please search again for the artist',
  search_owner: 'Search Owner',
  account_not_activated_yet: 'Account is not activated yet',
  we_will_send_your_request:
    'We will send your request to this email <strong>{{email}}</strong> ? Please check the email address before continuing to the next step!',
  we_will_send_your_request_to_this_email:
    'We will send your request to this <strong>{{email}}</strong>, please wait for their response.',
  in_he_meantime_please_fill:
    'In the meantime, please fill in the owner name in the box below manually.',
  please_enter_name: 'Please enter name',
  want_to_change_name_search_again: 'Want to change the name? <strong>{{search}}</strong>.',
  search_again: 'Search again',
  matching: 'Matching',
  text_approve_certificate_request:
    'To approve the certificate request you need to match the information in the request with your exact artwork and edition number.',
  artwork: 'Artwork',
  please_select_name_artist: 'Please select an artist"s name',
  add_artwork: 'Add artwork',
  search: 'Search',
  owner_searching: 'Owner searching',
  current_edition_volume: 'Current Edition Volume',
  status_and_location: 'Status & Location',
  new_artwork: 'New artwork',
  this_edition_has_created: 'This edition has created the certificate/contract.',
  the_vietnam_leading_online_art_platform: 'The Vietnam’s leading online art platform',
  gladius_art_is_more_than_an_online_platform:
    "Gladius Art is more than just an online platform; it's a dynamic ecosystem designed to empower artists, connect collectors, and enrich art galleries in an unprecedented way.",
  our_mission: 'Our Mission',
  our_services: 'Our Services',
  main_features: 'Main Features',
  what_is_gladius_art: 'What is Gladius Art',
  empowering_artists: 'Empowering Artists',
  at_gladius_art:
    "At Gladius Art, we're dedicated to breaking down barriers in the art world. Our platform is designed to make art accessible to everyone, regardless of location or traditional constraints. Specifically tailored to meet the needs to address the challenges they face in an art market often difficult to access.",
  breaking_traditional_constraints: 'Breaking Traditional Constraints',
  by_providing_digital:
    "By providing a digital space for artists to showcase their talent to a global audience, we're democratizing art and fostering connections between creators and enthusiasts worldwide. Join us as we redefine the art landscape, celebrating cultural diversity and empowering artists to share their unique perspectives with the world.",
  gladius_art_provides_innovative:
    'Gladius Art provides its innovative services to artists, collectors, and art galleries alike, fostering a dynamic and inclusive community within the global art sphere.',
  collectors: 'Collectors',
  art_galleries: 'Art Galleries',
  gladius_art_offers_range:
    'Gladius Art offers a range of tools and services to elevate your craft and expand your reach. From our Online Gallery to our intuitive Search and Upload feature, showcasing your artwork has never been easier. Join our platform to connect with fellow creators and engage with a global audience of art enthusiasts.',
  discover_acquire_unique:
    'Discover and acquire unique artworks from talented artists worldwide with Gladius Art. Our curated selection and personalized profiles make finding your next masterpiece effortless. Join our platform to explore and engage with a diverse range of artistic expressions.',
  join_us_at_gladius_art:
    "Join us at Gladius Art and be part of a vibrant community where creativity knows no bounds. Whether you're an artist, collector, or art gallery, we invite you to explore, connect, and thrive in the ever-evolving world of art.",
  join_us_now: 'Join us now',
  discover_the_core_functionalities: 'Discover the core functionalities of Gladius Art',
  online_gallery_your_artworks: 'Online Gallery for your Artworks',
  showcase_your_createtion:
    'Showcase your creations to a worldwide audience through our virtual gallery space.',
  start_exploring: 'Start Exploring',
  search_and_upload_artworks: 'Search and upload Artworks',
  easy_upload_and_tag: 'Easily upload and tag your artworks for increased discoverability.',
  create_your_art_certificate: 'Create your Art Certificate',
  use_our_in_platform_certificate:
    'Use our in-platform certificate issuance and management system to assure your clients of the authenticity and value of your artworks.',
  manage_your_artworks_and_editions: 'Manage your Artworks and Editions',
  streamline_inventory_management:
    'Streamline inventory management and transactions with our intuitive backend tools.',
  created_year: 'Created year',
  contact_us: 'Contact us',
  for_more_information: 'For more information or any support, please feel free to contact us.',
  phone: 'Phone',
  year_of_creation: 'Year of Creation',
  medium_material: 'Medium/Material',
  measurement_w_h: 'Measurement HxW(xD)',
  ask_for_price: 'Ask for price',
  about_artwork: 'About artwork',
  other_created_year: 'Other work in the same created year',
  in_transaction_process: 'In transaction process',
  platform_version: 'Platform version',
  certificate_number: 'Certificate number',
  date: 'Date',
  text_certificate_property_rights:
    'GladiusArt is the sole provider of ownership registration services relating to this artwork, Only the person of entity currently registered with GladiusArt as the owner is authorized to transfer this artwork to new ownership. Please contact GladiusArt for any assistance.',
  text_certificate_address:
    'Floor 12B, Cienco4 Building, 180 Nguyen Thi Minh Khai Street, Vo Thi Sau Ward, District 3, Ho Chi Minh City, Vietnam',
  all_rights_reserved: 'All rights reserved.',
  select_role: 'Select role',
  gallery_owner: 'Gallery owner',
  online_certificate: 'Online Certificate',
  hide_certificate_only_show_for_me: 'Hide certificate, only show for me',
  public_certificate_for_eveyone: 'Public certificate for eveyone',
  control_how_artwork_is_viewed: 'Control how this artwork is viewed',
  public: 'Public',
  artwork_details_page_visible_everyone: 'Artwork details page is visible to everyone.',
  only_you_can_see_this_artwork: 'Only you can see this artwork in your Manage Storage.',
  the_artist_is_unknown: 'The artist is unknown',
  visibility_settings: 'Visibility settings',
  text_setting_artist_profile_visible:
    'To show your Artist Profile, the visibility is now set to <strong>{{value}}</strong>.',
  exhibitions: 'Exhibitions',
  text_setting_exhibitions_visible:
    'To show the number of exhibitions in your Gallery, the visibility is now set to  <strong>{{value}}</strong>.',
  text_setting_artworks_visible:
    'To show the number of works in your Gallery, the visibility is now set to <strong>{{value}}</strong>.',
  text_setting_follower_visible:
    'To show the number of followers in your Gallery, the visibility is now set to <strong>{{value}}</strong>.',
  text_setting_social_media_visible:
    'To show the social media links in your Gallery, the visibility is now set to <strong>{{value}}</strong>.',
  text_setting_persional_information_visible:
    'To show your personal information in your Gallery, the visibility is now set to <strong>{{value}}</strong>.',
  social_media_links: 'Social media links',
  personal_information: 'Personal information',
  public_on_my_gallery: 'Public on My Gallery',
  private_on_my_gallery: 'Private on My Gallery',
  settings: 'Settings',
  all_works: 'All works',
  only_show_for_me: 'Only show for me',
  cost_price: 'Cost Price',
  selling_price: 'Selling Price',
  legal_name: 'Legal name',
  nick_name: 'Nick name',
  please_verify_your_infomation_here: 'Please verify your information here',
  id_account: 'ID Account',
  nick_name_account_name: 'Nickname (Account name)',
  unlimited: 'Unlimited',
  enter_your_email_social_link: 'Enter your email, or social link...',
  your_contact_information: 'Your contact information',
  please_enter_your_contact: 'Please enter your contact',
  please_enter_price: 'Please enter price',
  hide_selling_price: 'Hide selling price, only show to me',
  we_cannot_find_the_artist_with_your_provided:
    'We cannot find the artist with your provided information in the platform. Please try to search again or click ‘Add manually’ to manually fill in the artist name.',
  add_manually: 'Add manually',
  modify_attributes: 'Modify attributes',
  select_orientation: 'Select Orientation',
  artwork_images: 'Artwork Images',
  please_enter_artist_name_manually: 'Please enter artist’s name manually',
  no_i_will_do_it_later: 'No, I’ll do it later',
  we_cannot_find_an_account_that_match_your_mail:
    'We cannot find an account that match your email/phone number. You’re only able to request certificate to a account that available on our platform.',
  do_you_want_to_try_search_again: 'Do you want to try to search again?',
  edition_number_is_required: 'Edition number is required',
  artwork_description: 'Artwork description',
  tagged_request_details: 'Tagged Request Details',
  requestor: 'Requestor',
  text_are_you_sure_approve_tag_request:
    'Are you sure you want to approve the tagged request from <strong>[{{name}}]</strong>? Your information will be displayed as an Artist of this artwork.',
  text_placeholder_reject_request: 'Please provide a reason why you want to reject this request...',
  resend_success: 'Resend success',
  tagged_request: 'Tagged request',
  active_artists: 'Active artists',
  add_artist_manually: 'Add artist manually',
  enter_the_legal_name_or_nick_name: 'Enter the legal name or nick name to search',
  search_legal_name_nickname: 'Search by legal name or nickname...',
  add_artist_name: 'Add artist name',
  enter_artist_name: 'Enter artist name',
  email_phone_number: 'Email or phone number',
  enter_artist_email_phone_number: 'Enter artist’s email or phone number, if any',
  adding_the_artist_name_along:
    'Adding the artist name along with their contact information can help us detect when they become active on the platform and notify you.',
  please_enter_correct_email_phone_number: 'Please enter correct email or phone number',
  please_enter_artist_name: 'Please enter artist name',
  add_owner_name: 'Add owner’s name',
  adding_the_owner_name_along:
    "Adding the owner's name along with their contact information can help us detect when they become active on the platform and notify you.",
  enter_owner_name: "Enter owner's name",
  enter_owner_or_phone_number: "Enter owner's email or phone number",
  cancel_request_artist_tagging: 'Cancel request artist tagging for that artwork',
  the_artist_has_approved_tagged_request: 'The artist has approved your tagged request.',
  active_users: 'Active users',
  other_works_same_artist: 'Other works in the same artist',
  new_artist_profile: 'New artist profile',
  receiver: 'Receiver',
  request_canceled: 'Request canceled',
  this_request_cancelled: 'This request has been cancelled.',
  saved_owners: 'Saved owners',
  create_collection: 'Create Collection',
  select_the_artworks_want_add_collection: 'Select the artworks you want to add to this collection',
  collection_detail: 'Collection detail',
  collection_title: 'Collection title',
  give_your_collection_title: 'Give your collection a title',
  collection_visibility: 'Collection visibility',
  control_how_collection_is_viewed: 'Control how this collection is viewed',
  collection_visible_everyone: 'Collection is visible to everyone.',
  only_you_see_this_collection: 'Only you can see this collection in your Manage Storage.',
  add_artworks_to_collection: 'Add artworks to collection',
  select_artworks: 'Select artworks',
  collection_created_successfully: 'Collection created successfully',
  selected: 'selected',
  no_results_that_match_your_search: 'No results that match your search.',
  please_try_adjust_your_keywords:
    'Please try to adjust the your keywords or the filters for better results.',
  search_id_artwork_name: 'Search by ID or artwork name',
  edit_collection: 'Edit Collection',
  collection_edited_successfully: 'Collection edited successfully',
  day_created: 'Day created',
  are_you_sure_you_want_to_reject:
    'Are you sure you want to reject the tagged request from <strong>[{{name}}]</strong>? This action cannot be undone.',
  the_artist_has_rejected_tagged_request: 'The artist has rejected your tagged request.',
  edit: 'Edit',
  collection: 'Collection',
  select_collection: 'Select collection',
  create_new_collection: 'Create new collection',
  collection_name: 'Collection name',
  please_input_collection_name: 'Please input a collection name',
  by_default_the_visibility_collection:
    'By default, the visibility of this collection will be set as Private. You can always change it in Manage Collection.',
  other_collections_same_artist: 'Other collections of the same artist',
  text_warning_share_social: 'Anyone on the internet with the link can view.',
  share_via_social_media: 'Share via social media',
  or_copy_this_link: 'Or copy this link',
  copy_success: 'Copy success',
  you_can_attach_evidence_documents:
    'You can attach evidence documents, proof images to convince the artist. Allowed formats include: PDF, PNG, JPG, with a maximum of 5 files.',
  edition_number_exceeds_total: 'The edition number is greater than total edition.',
  text_upload_artwork_status_public: 'Public, show on My gallery, Home and My storage',
  text_upload_artwork_status_private: 'Private, show on My gallery and My storage.',
  of_original_artwork: 'of Original Artwork',
  height_abbreviation: 'H',
  width_abbreviation: 'W',
  deep_abbreviation: 'D',
  file_size_exceeded: 'The PDF file exceeds the 200MB limit',
  now: 'Now',
  showing_results_by_filter: 'Showing {{number}} results by filter',
  no_collection_available: 'No collection available',
  all: 'All',
  sort: 'Sort',
  sold_and_donated_gifted: 'Sold and Donated/Gifted',
  create_contract: 'Create contract',
  delete_edition: 'Delete edition',
  are_you_sure_want_delete_edition: 'Are you sure you want to delete this edition?',
  search_subject: 'Search subject...',
  create_share_link: 'Create share link',
  page_not_found: 'Sorry, page not found.',
  text_page_not_exist:
    "The page you were looking for doesn't exist. It may have been removed or is temporarily unavailable.",
  back_to_home: 'Back to home',
  search_collection: 'Search collection',
  my_page: 'My page',
  text_user_name_page: '{{name}} page',
  go_to_artist_page: "Go to artist's page",
  back_to_collector_page: "Back to collector's page",
  enter_year_of_birth: 'Enter year of birth',
  share_link_show: 'Share link show',
  year_of_death: 'Year of death',
  this_artist_not_yet_available: 'This artist is not yet available on GladiusArt.',
  image_not_public: 'Image not public',
  delete_collection: 'Delete collection',
  are_you_sure_to_delete_this_collection: 'Are you sure to delete this collection?',
  show_on_my_page_for_everyone: 'Show on My page for everyone',
  only_show_my_page_to_me: 'Only show on My page to me',
  create_exhibition: 'Create exhibition',
  exhibition_information: 'Exhibition information',
  please_fill_in_all_information: 'Please fill in all information about your exhibition',
  cover: 'Cover',
  we_recommend_you_to_portrait: 'We recommend you to use your portrait with file size under 10MB.',
  write_your_description_about_the_exhibition: 'Write your description about the exhibition...',
  exhibition_detail: 'Exhibition detail',
  give_your_exhibition_title: 'Give your exhibition title',
  exhibition_title: 'Exhibition title',
  date_start: 'Date start',
  date_end: 'Date end',
  exhibition_organizer: 'Exhibition organizer',
  enter_name: 'Enter name',
  type_of_event: 'Type of event',
  exhibition_groupings: 'Exhibition groupings',
  text_fill_exhibition_group:
    'Please upload the artworks you want to exhibit. You can categorize the artworks by using grouping function.',
  group_1: 'Group 1',
  enter_a_title: 'Enter a title',
  add_artwork_to_exhibition: 'Add artwork to exhibition',
  add_new_group_of_artworks: 'Add new group of artworks',
  delete_group: 'Delete group',
  free_ticket: 'Free Ticket',
  ticket_sale: 'Ticket Sale',
  private_event: 'Private Event',

  chat: 'Chat',
  saved_as_draft_successfully: 'Saved as draft successfully.',
  are_you_sure_to_delete_this_exhibition: 'Are you sure to delete this exhibition?',
  on_going: 'ON-GOING',
  draft: 'DRAFT',
  edit_exhibition: 'Edit exhibition',
  event_organizer: 'Event organizer',
  view_virtual_exhibition: 'View Virtual Exhibition',
  preface: 'Preface',
  back_step: 'Back step',
  year_of_birth_earliest_most_recent: 'Year of birth, earliest to most recent',
  year_of_birth_most_recent_earliest: 'Year of birth, most recent to earliest',
  alphabetical_a_z: 'Alphabetical, A to Z',
  alphabetical_z_a: 'Alphabetical, Z to A',
  prefaceMaxLength: 'Preface must not exceed 10000 characters',
  exhibitionTitleMaxLength: 'Exhibition title must not exceed 100 characters',
  exhibitionTitleRequired: 'Exhibition title is required',
  dateStartTypeError: 'Date start must be a valid date in DD-MM-YYYY format',
  dateStartRequired: 'Date start is required',
  dateEndTypeError: 'Date end must be a valid date in DD-MM-YYYY format',
  dateEndRequired: 'Date end is required',
  dateEndMin: 'Date end must be after or equal to start date',
  addressMaxLength: 'Address must not exceed 100 characters',
  organizerNameMaxLength: 'Organizer name must not exceed 100 characters',
  curatorNameMaxLength: 'Curator name must not exceed 100 characters',
  titleRequired: 'Title is required',
  titleUnique: 'Title must be unique',
  artworksRequired: 'At least one artwork is required',
  descriptionMaxLength: 'Description must be at most 10000 characters',
  search_by_exhibition_name: 'Search by exhibition name',
  search_by_title_collection_name: 'Search by title collection name',
  no_year_infomation: 'No year information',
  to_year_cannot_earlier_than_year: 'To year cannot be earlier than year.',
  be_at_least_8_character: 'Be at least 8 character',
  have_at_least_one_lower_case_character: 'Have at least one lower case character',
  have_at_least_one_capital_character: 'Have at least one capital character',
  have_at_least_one_number_or_symbol: 'Have at least one number or symbol (!@#$%)',
  current_password: 'Current password',
  new_password: 'New password',
  your_password_must: 'Your password must',
  confirm_new_password: 'Confirm new password',
  confirm_your_new_password: 'Confirm your new password',
  enter_your_current_password: 'Enter your current password',
  enter_your_new_password: 'Enter your new password',
  all_notifications: 'All notifications',
  mark_all_as_read: 'Mark all as read',
  enter_year_leave_blank_if_unknown: 'Enter year or leave blank if unknown',
  old_password_required: 'Old password is required',
  password_min_length: 'Password must be at least 8 characters',
  password_lowercase: 'Password must contain at least one lowercase letter',
  password_uppercase: 'Password must contain at least one uppercase letter',
  password_symbol_or_number: 'Password must contain at least one number or symbol (!@#$%)',
  password_required: 'Password is required',
  passwords_must_match: 'Passwords must match',
  confirm_password_required: 'Confirm password is required',
  text_receive_request_certificate:
    '{{request_user_name}} requested a certificate of the artwork {{artwork_title}}',
  the_art_data_management_platform: 'The Art Data Management Platform',
  gladius_art_is_more_than_just_an_online_platform:
    "Gladius Art is more than just an online platform; it's a dynamic ecosystem designed to empower for the daily activity for artist, collector and gallery in storing and tracking essential data easily and conveniently.",
  gladiusArt_is_spearheading:
    ' GladiusArt is spearheading a strong revolution in transparency within the art market, setting a standard for transparency over time.',
  we_provide_advanced_art_data_storage_tools:
    'We provide advanced art data storage tools to enhance efficiency in managing and connecting work among relevant entities in the art industry (artists, collectors, galleries, museums, etc.), promoting innovation and development.',
  gladius_art_offers_range_tools:
    'Gladius Art offers a range of tools and services to elevate your craft and expand your storage capacity. From our Online Gallery to our intuitive Search and filter and Upload feature, showcasing your artwork has never been easier.',
  an_art_data_management_platform_helps_collectors_organize:
    'An art data management platform helps collectors organize and track their collection with digital cataloging, provenance tracking, and secure documentation. It provides valuation insights, market trends, and insurance management to protect assets',
  gladius_art_provides_dynamic_platform:
    'Gladius Art provides a dynamic platform for galleries to showcase their collections to a global audience. Streamline inventory management and transactions with our backend tools and offer greater certainty to your buyers by issuing authorized certificates in-platform. Join us to simplify your processes and reach out more effective in your bussiness activities.',
  certification: 'Certification',
  share_exhibition: 'Share exhibition',
  curator: 'Curator',
  related_artists: 'Related artists',
  inventory_management: 'Inventory management',
  manage_your_art_gallery_professionally:
    'Manage your art gallery professionally and conveniently.',
  artwork_artist_management_advanced:
    'Artwork & Artist Management with Advanced Filter, Search & Sort',
  check_activity_log_and_turn_data:
    'Check activity log and turn data into every type of document: letters, emails, offers, consignments, invoices, labels, shipping forms, lists, reports, fact sheets, and more ',
  mobile_app: 'Mobile App',
  issued_certificates_and_evidences:
    'Issued Certificates and Evidences proving the Artwork’s Authenticity',
  propose_various_solutions_multiple:
    'Propose various solutions at multiple levels to help user protect and preserve the evidence proving the origin of artworks to increase the value of the artwork.',
  certificate_authenticity_artworks: 'Certificate of authenticity for artworks.',
  easy_management_and_tracking: 'Easy management and tracking.',
  artistic_showcase: 'Artistic showcase',
  share_the_private_gallery:
    'Share the private gallery, create the exhibition and secure the private data.',
  provide_personal_pages_for_users_showcase:
    'Provide personal pages for users to showcase their artworks.',
  provide_tools_to_curate_collection:
    "Provide tools to curate collections, online exhibitions and virtual exhibitions to enhance the user's online art viewing experience.",
  ensure_data_security_and_controlled_sharing: 'Ensure data security and controlled sharing.',
  relationship_management: 'Relationship management',
  build_sustainable_relationship:
    'Build sustainable relationship between artists. collectors and galleries',
  chatting_tool: 'Chatting tool.',
  detailed_contact_profiles_for_storing:
    'Detailed contact profiles for storing and accessing information.',
  sales_opportunity_tracking: 'Sales opportunity tracking and follow-up purchasing history.',
};

export default enTranslation;
